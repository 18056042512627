<template>
  <div id='related-posts'>
    <template v-for="post in state.relatedPosts" :key="post.id">
      <div class="post-item" :title="post.title">
        <a :href="post.path" class="block w-full relative">
          <div
            v-if="!(parseFloat(post.price) > 0)"
            class="w-10 h-4 text-center font-medium absolute free-tag top-0 left-2 pb-0.5 bg-red text-xs ml-2 mt-2"> 
            {{ $t('post_item.free') }}
          </div>
          <div
            v-if="post.phrozen_verified"
            class="flex items-center justify-center w-10 h-4 text-center font-medium absolute pv-tag top-0 left-2 bg-red text-12px ml-2 mt-2">
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-0.5">
              <path d="M5.99941 9.57694C8.22979 9.57694 10.0379 7.76885 10.0379 5.53847C10.0379 3.30808 8.22979 1.5 5.99941 1.5C3.76902 1.5 1.96094 3.30808 1.96094 5.53847C1.96094 7.76885 3.76902 9.57694 5.99941 9.57694Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.9982 7.15363C6.89035 7.15363 7.61359 6.43039 7.61359 5.53824C7.61359 4.64609 6.89035 3.92285 5.9982 3.92285C5.10605 3.92285 4.38281 4.64609 4.38281 5.53824C4.38281 6.43039 5.10605 7.15363 5.9982 7.15363Z" fill="white"/>
              <path d="M8.15536 11.9996L6.00119 9.8457L3.84766 11.9996" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>

              PV
          </div>
          <div class="aspect-square">
            <img v-lazy="post.cover_image_src" :alt="post.title" />
          </div>
          <span class="image-mask">
            <div v-if="parseFloat(post.price) > 0"
              class="post-price-container flex items-center absolute bottom-0 ml-2">
              <span class="post-price">
                <Currency :price="post.price" />
              </span>
              <span
                v-if="parseFloat(post.price) < parseFloat(post.original_price)"
                class="post-original-price"
              >
                <Currency :price="post.price" />
              </span>
            </div>

            <div v-else
                class="post-data-container items-center absolute bottom-0 left-0 mb-2 ml-2">
              <div v-if="parseFloat(post.views_count) >= 5" 
                  class="post-view-count flex items-center pr-2">
                <div class="icon mr-1 w-4 h-4"></div>
                  {{ formatNumber(post.views_count) }}
              </div>
              <div v-if="parseFloat(post.likes_count) >= 5" 
                  class="post-likes-count flex items-center pr-2">
                <div class="icon mr-1 w-4 h-4"></div>
                  {{ formatNumber(post.likes_count) }}
              </div>
              <div v-if="parseFloat(post.downloads_count) >= 5"
                  class="post-downloads-count flex items-center pr-2">
                <div class="icon mr-1 w-4 h-4"></div>
                  {{ formatNumber(post.downloads_count) }}
              </div>
            </div>
          </span>
        </a>
      </div>
    </template>
  </div>
</template>
<script>
import { state } from "../stores/postState";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      state
    };
  },
  mounted() {
    this.state.post_id = window.location.href.match(/\/posts\/(\d+)/)[1]
    this.state.relatedPosts = []

    this.getRelatedPosts()
  },
  methods: {
    async getRelatedPosts(){
      try {
        const api_path = '/api/v1/posts/' + this.state.post_id + '/related_posts'
        const response = await fetch(api_path);

        if (response.ok) {
          const data = await response.json();
          const postsData = data['data']

          if (postsData.length > 0) {
            this.state.relatedPosts = postsData;
          }
        }
      } catch (error) {
        this.state.relatedPosts = []
      }
    },
    formatNumber(value) {
      if (!value) return '0';
      value = Number(value);
      if (value < 1000) return value.toString();
      const result = (value / 1000).toFixed(1);
      return result + 'k';
    }
  }
});
</script>
