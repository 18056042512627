// state.js
import { reactive } from 'vue';

export const state = reactive({
  post_id: null,
  CSRFtoken: null,
  curr_profile: null,
  post_profile: null,
  images: [],
  aspectRatio: [1, 1]
})

export const commentState = reactive({
  reviewable: false,
  has_review: false,
  edit_target: null,
  default_stars: 0,
  comment_count: 0,
  order_type: '',
  comment_items: [],
  selected_comment_items: [],
  reviewStars: [],
  reply_comment_id: '',
  reply_username: ''
});

export const reportState = reactive({
  openModal: false,
  targetId: null,
  targetType: null,
  reportKey: null,
  reportReason: null
});

export const slideState = reactive({
  fetchPath: '/comment_images',
  openSlide: false,
  images: [],
  startImageId: null,
  aspectRatio: [1, 1]
});

export const exchangeState = reactive({
  rates: null
});
