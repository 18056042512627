<template>
  <div class="w-full flex flex-wrap">
    <div class="flex-1">
      <div class="review-search-form flex flex-col lg:flex-row">
        <div class="flex flex-1 flex-col lg:flex-row w-full lg:w-2/3 lg:mb-0 justify-between items-center">
          <div class="flex flex-col w-full">
            <div class='comment-order-type relative pr-2'> 
              <div class="icon brightness-50"></div>
              <template v-for="type in orderTypes" :key="type.value">
                <input type="radio" 
                      name='comment-order-type' 
                      class='hidden' 
                      :id="'order_type_' + type.value" 
                      :value="type.value" 
                      :checked="state.orderType === type.value">
                <label class='text-grey-3 hover:text-grey-5' 
                      :for="'order_type_' + type.value" 
                      @click="changeOrderType(type.value)">
                  {{ $t(`models.index.${type.label}`) }}
                </label>
                <span class='text-grey-3' v-if="!type.isLast">|</span>
              </template>
              <div class="absolute right-0">
                <nav-panel :submitErrors="{title: 'Error Title', content: 'Error Content'}"
                          :panelClass="'w-[288px]'">
                  <template #icon>
                    <img src="../../images/circle_help.svg" class="w-6 h-6" alt="Warning" />
                  </template>
                  <template #content>
                    <div class="custom-content text-[12px]">
                      <p class=" font-bold">{{ $t(`models.index.sort_defined`) }}</p>
                      <ul class="ml-4">
                        <template v-for="item in sortDefinitions" :key="item">
                          <li class="list-disc text-orange-10 mt-2">{{ $t(`models.index.${item}`) }}</li>
                          <p class="mb-2">{{ $t(`models.index.${item}_defined`) }}</p>
                        </template>
                      </ul>
                    </div>
                  </template>
                </nav-panel>
              </div>
            </div>
            <div class="relative mt-4 hidden">
              <div class="search-icon" @click="handleSearch"></div>
                <input 
                  v-model="state.currentSearch" 
                  type="search" 
                  :placeholder="$t('models.model_search_form.model_title')" 
                  class="flex review-search-bar  w-full h-10 bg-grey-4 py-2 px-4 rounded-3xl sm:w-[292px] border border-transparent focus:outline-none focus:border-[#2bdee9] placeholder:text-[16px]"
                  @keydown.enter="handleSearch"
                  @search="handleSearch">
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 text-14px">
        <div v-if="state.initialLoading || state.loading" class="flex flex-col items-center justify-center aspect-[828/582]">
          <p class="text-16px font-medium text-center">
            {{ $t('models.index.loading') }}
          </p>
        </div>

        <div v-else-if="state.currentUser && state.items.length === 0" class="flex flex-col items-center justify-center aspect-[828/582]">
          <img src="../../images/sad.svg" class="block w-12 h-12 mb-4" />
          <p class="text-16px font-medium text-center">
            {{ $t('models.index.no_model_now') }}
            <br>
            {{ $t('models.index.create_your_model') }}
          </p>
        </div>     
        <div v-else >
          <div class="text-grey-5 mb-2">{{ `Pin (${pinnedItems.length}/8)` }}</div>
          <div class="pin-model-container max-h-full h-full overflow-auto scrollbar pr-1 -mr-3"
               >
            <sorted-model-item 
              v-for="(post, idx) in pinnedItems" 
              :key="post.id" 
              :post="post"
              :is-pinned="true"
              :show-pin-icon="true"
              :order-type="state.orderType"
              :index="getPinnedIndex(post.id)  === -1 ? idx : getPinnedIndex(post.id)"
              @moveToTop="(id) => handleSortAction(id, 'top', 'pinned')"
              @moveToBottom="(id) => handleSortAction(id, 'bottom', 'pinned')"
              @moveToPin="(id) => handleSortAction(id, 'unpin', 'pinned')"
            />
            <hr v-if="pinnedItems.length > 0" class="mb-2 border-grey-3" />
          </div>

          <div class="aspect-[828/582] model-container max-h-full h-full overflow-auto scrollbar pr-1 -mr-3">
            <sorted-model-item 
              v-for="(post, idx) in normalItems" 
              :key="post.id" 
              :post="post" 
              :is-pinned="false"
              :show-pin-icon="pinnedItems.length < 8"
              :order-type="state.orderType"
              :pin-count="pinnedItems.length"
              :index="getNormalIndex(post.id) === -1 ? idx : getNormalIndex(post.id)"
              @moveToTop="(id) => handleSortAction(id, 'top', 'normal')"
              @moveToBottom="(id) => handleSortAction(id, 'bottom', 'normal')"
              @moveToPin="(id) => handleSortAction(id, 'pin', 'normal')"
            />
          </div>
        </div>
        <div class="flex justify-center pt-4">
          <button class="btn btn-secondary" @click="cancelSort">Cancel</button>
          <button class="btn btn-primary ml-4" 
                  @click="saveSortOrder"
                  :disabled="state.isSaving">
            {{ state.isSaving ? 'Saving...' : $t('models.index.save_sort') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, reactive, computed, watch, onMounted, onUnmounted, nextTick} from "vue";
import Sortable from 'sortablejs';

export default defineComponent({
  setup() {
      const sortDefinitions = [
      'pv',
      'published_date',
      'price',
      'custom_order'
    ];

    const state = reactive({
      currentUser: null,
      orderType: 'published_date',
      isSaving: false,
      hasCustomOrder: false,
      hasVerifiedItems: false,
      items: [],
      pinnedItemIds: [],
      normalItemIds: [],
      loading: false,
      initialLoading: true,
      currentSearch: '',
    });

    const sortableInstances = ref({
      normal: null,
      pinned: null,
      pinnedInitialized: false,
      normalInitialized: false,
    });

    const orderTypes = computed(() => {
      const types = [
        { value: 'published_date', label: 'published_date' },
        { value: 'price', label: 'price' },
        { value: 'custom_order', label: 'custom_order' }
      ];
 
      if (state.hasVerifiedItems) {
        types.unshift({ value: 'pv', label: 'pv' });
      }
      types[types.length - 1].isLast = true;
      return types;
    });

    const pinnedItems = computed(() => {
      const pinnedItems = state.items.filter(item => state.pinnedItemIds.includes(item.id));
      return sortItemsByType(pinnedItems, state.pinnedItemIds, state.orderType);
    });

    const normalItems = computed(() => {
      const normalItems = state.items.filter(item => !state.pinnedItemIds.includes(item.id));
      return sortItemsByType(normalItems, state.normalItemIds, state.orderType);
    });

    // Data fetching and processing methods
    const fetchCurrentUser = async () => {
      try {
        const response = await fetch('/api/v1/profiles/current_user', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          state.currentUser = await response.json();
        } else {
          throw new Error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    const fetchSavedOrder = async () => {
      try {
        const response = await fetch('/api/v1/profiles/sorted_models');
        if (response.ok) {
          const { normal, pinned } = await response.json();
          state.normalItemIds = normal || [];
          state.pinnedItemIds = pinned || [];
          state.hasCustomOrder = state.normalItemIds.length > 0 || state.pinnedItemIds.length > 0;
        }
      } catch (error) {
        console.error('Error fetching sorted data:', error);
      }
    };

    const fetchItems = async () => {
      state.loading = true;
      const url = `/api/v1/profiles/all_models`;
      const params = new URLSearchParams({
        kw: state.currentSearch,
      });

      try {
        const response = await fetch(`${url}?${params}`);
        if (response.ok) {
          state.items = await response.json();
          reconcileOrdersWithItems();
        }
      } catch (error) {
        console.error('Error fetching model data:', error);
      }
      finally {
        state.initialLoading = false;
        state.loading = false;
      }
    };

    const reconcileOrdersWithItems = () => {
      const allItemIds = state.items.map((item) => item.id);
      updatePinnedItemIds(allItemIds);
      updateNormalItemIds(allItemIds);
    };

    const updatePinnedItemIds = (allItemIds) => {
      state.pinnedItemIds = state.pinnedItemIds.filter((id) => allItemIds.includes(id));
    };

    const updateNormalItemIds = (allItemIds) => {
      const pinnedIdsSet = new Set(state.pinnedItemIds);

      state.normalItemIds = state.normalItemIds.filter(
        (id) => allItemIds.includes(id) && !pinnedIdsSet.has(id)
      );

      const existingIdsSet = new Set([...state.pinnedItemIds, ...state.normalItemIds]);
      const newItemIds = allItemIds.filter((id) => !existingIdsSet.has(id));
      state.normalItemIds.push(...newItemIds);
    };

    // Sorting related methods
    const sortItemsByType = (itemsToSort, orderArray, sortType) => {
      switch (sortType) {
        case 'custom_order':
            return sortByCustomOrder(itemsToSort, orderArray);
        case 'price':
          return itemsToSort.sort((a, b) => b.price - a.price);
        case 'published_date':
          return itemsToSort.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        case 'pv':
          return itemsToSort.sort((a, b) => {
            if (a.phrozen_verified && !b.phrozen_verified) return -1;
            if (!a.phrozen_verified && b.phrozen_verified) return 1;
            return new Date(b.created_at) - new Date(a.created_at);
          });
        default:
          return itemsToSort;
      }
    };

    const sortByCustomOrder = (itemsToSort, orderArray) => {      
      if (orderArray.length > 0) {
        const sortedItems = orderArray.map((id) => itemsToSort.find((item) => item.id === id))
                                      .filter(Boolean);
        const remainingItems = itemsToSort.filter((item) => !orderArray.includes(item.id));
        return [...sortedItems, ...remainingItems];
      }
      return itemsToSort;
    };

    // User Interface Interaction and Event Handling
    const handleSearch = async () => {
      await fetchItems();
    };

    const changeOrderType = async (type) => {      
      if (state.orderType === type) return;
      state.orderType = type;      
      await nextTick();
  
      // If not custom_order, update IDs based on new sorting
      if (type !== 'custom_order') {
        updateOrderFromDOM('normal');
        updateOrderFromDOM('pinned');
      }
    };

    const handleSortAction = (id, action, source) => {
      if (state.orderType !== 'custom_order' && action !== 'pin' && action !== 'unpin') {
        return; // Ignore other actions when not in 'custom_order' state
      }
      id = Number(id);
      
      switch(action) {
        case 'top': {
          const targetArray = source === 'normal' ? state.normalItemIds : state.pinnedItemIds;
          const index = targetArray.indexOf(id);
          if (index > -1) {
            targetArray.splice(index, 1);
            targetArray.unshift(id);
          }
          break;
        }
        case 'bottom': {
          const targetArray = source === 'normal' ? state.normalItemIds : state.pinnedItemIds;
          const index = targetArray.indexOf(id);
          if (index > -1) {
            targetArray.splice(index, 1);
            targetArray.push(id);
          }
          break;
        }
        case 'pin': {
          const index = state.normalItemIds.indexOf(id);
          if (index > -1) {
            state.normalItemIds.splice(index, 1);
            state.pinnedItemIds.push(id);
          }
          break;
        }
        case 'unpin': {
          const index = state.pinnedItemIds.indexOf(id);
          if (index > -1) {
            state.pinnedItemIds.splice(index, 1);
            state.normalItemIds.push(id);
          }
          break;
        }
      }
    };

    const saveSortOrder = async () => {
      if (state.isSaving) return;

      state.isSaving = true;

      try {
        const response = await fetch('/api/v1/profiles/update_sorted_models', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
            pinned_order: state.pinnedItemIds,
            normal_order: state.normalItemIds
          })
        });

        if (!response.ok) {
          throw new Error('Failed to save sort order');
        }
        const result = await response.json();
        window.location.href = '/profile/sorted_models';
        return result;

      } catch (error) {
        console.error('Error saving sort order:', error);
        // 這裡可以添加錯誤提示
        alert('Failed to save sort order. Please try again.');
        // 回傳錯誤信息給前端
        return { error: 'Failed to save sort order. Please try again.' };
      } finally {
        state.isSaving = false;
      }
    };

    const cancelSort = () => {
      window.location.href = '/profile/models';
    };

    // Drag and Drop Sorting Initialization
    const initSortable = () => {      
      initPinnedSortable();
      initNormalSortable();
    };

    const initPinnedSortable = () => {      
      if (sortableInstances.value.pinnedInitialized) {
        return;
      }

      const pinnedContainer = document.querySelector('.pin-model-container');
      if (pinnedContainer && !sortableInstances.value.pinned) {
        sortableInstances.value.pinned = Sortable.create(pinnedContainer, {
          animation: 150,
          group: 'pinModels',
          sort: state.orderType === 'custom_order',
          onEnd: () => updateOrderFromDOM('pinned')
        });
        sortableInstances.value.pinnedInitialized = true;
      }
    };

    const initNormalSortable = () => {
      const normalContainer = document.querySelector('.model-container');
      if (normalContainer && !sortableInstances.value.normal) {
        sortableInstances.value.normal = Sortable.create(normalContainer, {
          animation: 150,
          group: 'normalModels',
          sort: state.orderType === 'custom_order',
          onEnd: () => updateOrderFromDOM('normal')
        });
        sortableInstances.value.normalInitialized = true;
      }
    };

    // Helper methods
    const formatDate = (date) => {
      return new Date(date).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    };

    const updateOrderFromDOM = async (type) => {
      const containerClass = type === 'normal' ? '.model-container' : '.pin-model-container';
      const newOrder = Array.from(document.querySelectorAll(`${containerClass} > div`))
                            .map(el => Number(el.dataset.modelId));  // 確保轉換為數字
      if (type === 'normal') {
        state.normalItemIds = [...newOrder];
      } else {
        state.pinnedItemIds = [...newOrder];
      }
    };

    const getPinnedIndex = (id) => {
      return state.pinnedItemIds.indexOf(id);
    };

    const getNormalIndex = (id) => {
      return state.normalItemIds.indexOf(id);
    };

    watch(
      () => state.orderType,
      (newOrderType) => {
        const sortable = newOrderType === 'custom_order';
        if (sortableInstances.value.normal) {
          sortableInstances.value.normal.option('sort', sortable);
        }
        if (sortableInstances.value.pinned) {
          sortableInstances.value.pinned.option('sort', sortable); 
        }
      }
    );

    onMounted(async () => {
      try {
        await fetchCurrentUser();
        if (!state.currentUser) {
          console.error('Failed to fetch current user');
          return;
        }

        await fetchSavedOrder();
        await fetchItems();
        
        state.hasVerifiedItems = state.items.some(item => item.phrozen_verified);
        
        state.orderType = state.hasCustomOrder 
          ? 'custom_order'
          : state.hasVerifiedItems ? 'pv' : 'published_date';

        initSortable();

      } catch (error) {
        console.error('Error in onMounted:', error);
      }
    });

    onUnmounted(() => {
      if (sortableInstances.value.normal) {
        sortableInstances.value.normal.destroy();
        sortableInstances.value.normal.destroy();

      }
      if (sortableInstances.value.pinned) {
        sortableInstances.value.pinned.destroy();
        sortableInstances.value.pinnedInitialized = false;
      }
    });

    return {
      sortDefinitions,
      state,
      orderTypes,
      pinnedItems,
      normalItems,
      handleSortAction,
      handleSearch,
      changeOrderType,
      saveSortOrder,
      cancelSort,
      formatDate,
      formatCurrency,
      getPinnedIndex,
      getNormalIndex,
    };
  }
});
</script>
